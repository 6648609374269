.tex-page .footer-tex {
  position: relative;
  background-color: #1E1E2F;
  padding: 21px 0px 3px;
}
.tex-page .footer-tex .footer-widget ul{
  display: flex;
}
.tex-page .footer-tex .footer-widget ul li {
  list-style: none;
  margin-right: 25px;
  position: relative;
}
.tex-page .footer-tex .footer-widget ul li:not(:last-child):before{
  content: "";
  position: absolute;
  right: -14px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #fff;
}
.tex-page .footer-tex .footer-widget ul li a {
  color: #fff;
}

.tex-page .footer-tex .footer-widget p {
  color: #fff;
  text-align: right;
}


@media(max-width: 991px){
  .tex-page .footer-tex .footer-widget ul{
      justify-content: center;
  }
  .tex-page .footer-tex .footer-widget p{
      text-align: center;
  }
  
}
@media(max-width: 480px){
  .tex-page .footer-tex .footer-widget ul li a,
  .tex-page .footer-tex .footer-widget p {
      font-size: 12px;
  }
  .tex-page .footer-tex .footer-widget ul li{
    margin-right: 15px;
  }
  .tex-page .footer-tex .footer-widget ul li:not(:last-child):before{
    right: -6px;
  }
}

@media(max-width: 400px){
  .tex-page .footer-tex .footer-widget ul li a,
  .tex-page .footer-tex .footer-widget p {
      font-size: 11px;
  }
  .tex-page .footer-tex .footer-widget ul li{
    margin-right: 10px;
  }
  .tex-page .footer-tex .footer-widget ul li:not(:last-child):before{
    right: -5px;
  }
}
