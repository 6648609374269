@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

/* 
  font-family: 'Mulish', sans-serif;
*/


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-input-placeholder{color: inherit;}
::-moz-input-placeholder{color: inherit;}
::-ms-input-placeholder{color: inherit;}

.auto-container{
	position:static;
	max-width:1270px;
	padding:0px 15px;
	margin:0 auto;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.2em;
	font-family: 'Mulish', sans-serif;
  color: #1E1E2F;
}

button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.app-text {
  text-align: center;
  color: #61dafb;
}
  
.landing-merkle {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s !important;
}

a{
	text-decoration:none;
	cursor:pointer;
}

a:hover,
a:focus,
a:visited{
	text-decoration:none;
	outline:none;
}

img{
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #301C3C;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #301C3C;
}


@media(max-width: 1450px){
  .auto-container{
    max-width: 1170px;
  }
}

@media(max-width: 1199px){
  .auto-container{
    max-width: 100%;
  }
}
